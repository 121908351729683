import 'custom-event-polyfill';

const triggerEvent = (element, name, data) => {
  const evt = new CustomEvent(name, {
      detail: data
  });
  element.dispatchEvent(evt);
};

export default {
    triggerEvent
};

