import ResizeObserver from "resize-observer-polyfill";
import AOS from 'aos';

function onBodyResize (entries, observer) {
    AOS.refresh();
    console.debug('AOS has been refreshed');
}

function setup () {
  AOS.init({
    offset: 0,
    duration: 900,
    once: true 
  });

    const observer = new ResizeObserver(onBodyResize);
    observer.observe(document.body);

    console.debug('AOS has been setup');
}

export default {
    setup
};

