import $ from 'jquery';
import 'slick-carousel';
import 'intersection-observer';
import EventDispatcher from '../util/event';

function setupWhenOnScreen (selector, options) {
    const elements = document.querySelectorAll(selector);
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0 && !entry.target.dataset['gloversureCarouselLoaded']) {
                console.debug(`Carousel for selector "${selector}" has been setup`);
                $(entry.target).slick(options);

                entry.target.dataset['gloversureCarouselLoaded'] = true;
                EventDispatcher.triggerEvent(entry.target, 'gloversure.carousel.loaded')
            }
        });
    }, {
        root: null,
        rootMargin: '0px',
    });

    for (let i = 0; i < elements.length; i++) {
        observer.observe(elements[i]);
    }
}

function setupNow (selector, options) {
    $(selector).slick(options);

    const elements = document.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
      EventDispatcher.triggerEvent(elements[i], 'gloversure.carousel.loaded')
    }

    console.debug(`Carousel for selector "${selector}" has been setup`);
}

function setup (selector, options) {
    const delay = typeof options.delay !== 'undefined' && options.delay;
    delete options.delay;

    if (delay) {
        setupWhenOnScreen(selector, options);
    } else {
        setupNow(selector, options);
    }
}

export default {
    setup,
};

