
const defaultOptions = {
  breakpoint: 910,
  selectors: {
    parent: '.woocommerce.widget_product_categories',
    title: '.widgettitle',
    content: '.product-categories',
  }
};

const closeContent = (parent, content) => {
  console.debug('Closing content');

  content.slideUp();
  parent.data('open', 'close');
  parent.removeClass('open');
};

const openContent = (parent, content) => {
  console.debug('Opening content');

  content.slideDown();
  parent.data('open', 'open');
  parent.addClass('open');
};

const setup = (options) => {
  options = { ...defaultOptions, ...options };

  const parent = $(options.selectors.parent);
  if (!parent) {
    console.debug('No parent found for accordion', options.selectors.parent);
    return;
  }

  const title = parent.find(options.selectors.title);
  const content = parent.find(options.selectors.content);

  title.on('click', function () {
    if (parent.data('open') === 'close') {
      openContent(parent, content);
    } else {
      closeContent(parent, content);
    }
  });

  if (window.innerWidth <= options.breakpoint) {
    closeContent(parent, content);
  }

};

export default {
  setup,
}
