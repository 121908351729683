import $ from 'jquery'
import 'readmore-js';

function setup (selector, options) {
  console.debug(`Readmore setup on selector ${selector} with options`, options);
  $(selector).readmore(options)
}

export default {
  setup,
};
