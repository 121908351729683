import $ from 'jquery';
import 'foundation-sites';

function setup () {
   $(document).foundation();
   console.debug('Foundation has been setup');
   console.debug('Do you really need all of foundation???');
}

export default {
    setup,
};
